<template>
  <section class="faq-delivery">
    <div class="container">
      <div class="wrapper">
        <div class="content">
          <h1 class="section-title mb-m">
            Доставка
          </h1>
          <p class="fs--medium fw--bold">
            Приобретенный пакет услуг CLICKTOID мы доставим любым, удобным для Вас способом
          </p>
          <div class="divider" />
          <p class="mb-m">
            Доставка в любую точку мира <br><br>
            Стоимость доставки курьерской службой рассчитывается индивидуально. <br><br>
            Срок доставки заказанного пакета услуг зависит от выбранного способа доставки.
          </p>
          <div class="delivery-services mb-xl">
            <div class="service">
              <img src="@/assets/img/faq/pochta.png">
            </div>
            <div class="service">
              <img src="@/assets/img/faq/kce.png">
            </div>
            <div class="service">
              <img src="@/assets/img/faq/flippost.png">
            </div>
          </div>
          <app-button
            class="btn-payment mb-l"
            title="К оплате"
            description="Активация, продления, смена держателя"
            @click.native="$emit('next-slide')"
          >
            <icon-info class="icon" />
          </app-button>
        </div>
        <div class="wrapper-img img-circle">
          <img src="@/assets/img/faq/delivery-img.png">
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import AppButton from '@/components/common/AppButton'
import IconInfo from '@/assets/img/icons/info.svg'

export default {
  name: 'FaqDelivery',
  components: {
    AppButton,
    IconInfo
  }
}
</script>

<style lang="scss" scoped>
  .faq-delivery {
    position: relative;
    &.active {
      .wrapper {
        .content {
          left: 0;
        }
        .wrapper-img {
          right: 0;
        }
      }
    }
    .container {
      justify-content: center;
    }
    .wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include max-w-md {
        flex-direction: column-reverse;
      }
      .content, .wrapper-img {
        position: relative;
        transition: 1s;
      }
      .wrapper-img {
        right: -2000px;
        @include max-w-md {
          max-width: 300px;
        }
      }
      .content {
        left: -2000px;
        margin-right: 80px;
        max-width: 420px;
        @include max-w-md {
          margin-right: 0;
        }
        .delivery-services {
          margin-left: -16px;
          margin-right: -16px;
          display: flex;
          align-items: center;
          .service {
            margin: 0 16px;
            filter: grayscale(1);
            opacity: .7;
          }
        }
        .btn-payment {
          max-width: 300px;
          .icon {
            path {
              fill: $dark;
            }
          }
        }
      }
    }
  }
</style>
