<template>
  <full-page-template>
    <faq-background :active-section="activeSection" />
    <div class="p-faq p-common" :class="{ unloaded : !loaded }">
      <button class="btn-move-next" :class="{ prev : isLastSection }" @click="nextSlide">
        <span />
        <span />
        <span />
      </button>
      <full-page id="fullpage" ref="fullpage" :options="fullPageOptions">
        <faq-delivery class="section" @next-slide="nextSlide" />
        <faq-pay class="section" @next-slide="nextSlide" />
        <div class="section">
          <faq-help :class="{ active : activeSection === 'help' }" @fullpage-rebuild="rebuild" />
          <app-footer />
        </div>
      </full-page>
    </div>
  </full-page-template>
</template>

<script>
import FaqBackground from '@/components/faq/inner/FaqBackground'
import FullPageTemplate from '@/layouts/FullPageTemplate'
import AppFooter from '@/components/common/AppFooter'
import FaqDelivery from '@/components/faq/FaqDelivery'
import FaqPay from '@/components/faq/FaqPay'
import FaqHelp from '@/components/faq/FaqHelp'

export default {
  name: 'FAQ',
  components: {
    FaqBackground,
    FullPageTemplate,
    AppFooter,
    FaqDelivery,
    FaqPay,
    FaqHelp
  },
  data () {
    return {
      activeSection: '',
      loaded: false,
      isLastSection: false,
      fullPageOptions: {
        licenseKey: 'FFB008DD-B64342CE-9D967973-12897C85',
        normalScrollElements: '.scrollable-content',
        onLeave: this.onLeave,
        scrollOverflow: true,
        anchors: ['delivery', 'pay', 'help']
      }
    }
  },
  mounted () {
     if (document.readyState === 'complete') {
      this.pageLoaded()
    } else {
      window.onload = () => {
        this.pageLoaded()
      }
    }
  },
  computed: {
    fullpage () {
      return this.$refs.fullpage.api
    }
  },
  watch: {
    $route (to) {
      this.fullpage.moveTo(to.hash.slice(1))
    }
  },
  methods: {
    rebuild () {
      setTimeout(() => {
        this.fullpage.reBuild()
      }, 300)
    },
    onLeave(origin, destination) {
      this.activeSection = destination.anchor
      this.isLastSection = destination.isLast
    },
    nextSlide () {
      this.isLastSection ? this.fullpage.moveTo(1) : this.fullpage.moveSectionDown()
    },
    pageLoaded () {
      this.activeSection = this.fullpage.getActiveSection().anchor
      this.loaded = true
    }
  }
}
</script>

<style lang="scss" scoped>
.p-faq {
  background: url('~@/assets/img/faq/pipe.png') top / contain no-repeat;
  .btn-move-next {
    @include min-w-xs {
      display: none;
    }
  }
}
</style>
