<template>
  <div class="backgrounds-faq" :class="activeSection">
    <div class="delivery" />
    <div class="pay" />
    <div class="help" />
  </div>
</template>

<script>
export default {
  name: 'FaqBackground',
  props: {
    activeSection: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
  .backgrounds-faq {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    div {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: 1s;
    }
    .delivery {
      background: url('~@/assets/img/faq/bg-delivery.png') center / cover no-repeat;
      @include max-w-md {
        background: url('~@/assets/img/faq/bg-delivery-mobile.png') center / cover no-repeat;
        background-position-y: -180px;
      }
    }
    .pay {
      background: url('~@/assets/img/faq/bg-pay.png') center / cover no-repeat;
    }
    .help {
      background: url('~@/assets/img/faq/bg-help.png') center / cover no-repeat;
    }
    &.delivery {
      .delivery {
        opacity: 1;
        transform: scale(1.2);
      }
    }
    &.pay {
      .pay {
        opacity: 1;
        transform: scale(1.2);
      }
    }
    &.help {
      .help {
        opacity: 1;
        transform: scale(1.2);
      }
    }
  }
</style>