<template>
  <section class="faq-pay">
    <div class="container">
      <div class="wrapper">
        <div class="wrapper-img left">
          <div class="img-circle">
            <img src="@/assets/img/faq/pay-img.png">
          </div>
          <div class="absolute-buttons">
            <app-link class="mb-s" to="/payment-rules">
              Правила оплаты, безопасность платежей и конфиденциальность информации
            </app-link>
            <app-link to="/refusal">
              Отказаться от сервиса
            </app-link>
          </div>
        </div>
        <div class="content right">
          <h1 class="section-title mb-m">
            Оплата
          </h1>
          <p class="fs--medium fw--bold">
            Если Вы выбрали подходящий для Вас пакет услуг и хотите его оплатить, Вам необходимо осуществить следующие действия:
          </p>
          <div class="divider" />
          <ul class="steps-list mb-m">
            <li class="list-item">
              Добавить выбранные пакеты услуг в корзину
            </li>
            <li class="list-item">
              Перейти в корзину и нажать кнопку "Оформить заказ"
            </li>
            <li class="list-item">
              Заполните форму заказа и перейдите к оплате
            </li>
            <li class="list-item">
              После проведения оплаты с Вами свяжется менеджер для уточнения адреса доставки заказа
            </li>
            <li class="list-item">
              Оформление завершено. Ожидайте звонок курьера.
            </li>
          </ul>
          <div class="mb-xl payment-systems">
            <img src="@/assets/img/payment-systems.png">
          </div>
          <app-button
            class="btn-faq"
            title="К FAQ"
            description="Активация, продления, смена держателя"
            @click.native="$emit('next-slide')"
          >
            <icon-question class="icon" />
          </app-button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import AppButton from '@/components/common/AppButton'
import AppLink from '@/components/common/AppLink'
import IconQuestion from '@/assets/img/icons/question.svg'

export default {
  name: 'FaqPay',
  components: {
    AppButton,
    AppLink,
    IconQuestion
  }
}
</script>

<style lang="scss" scoped>
  .faq-pay {
    position: relative;
    &.active {
      .wrapper {
        .wrapper-img {
          left: 0;
        }
        .content {
          right: 0;
        }
      }
    }
    .container {
      justify-content: center;
    }
    .wrapper {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include max-w-md {
        flex-direction: column;
      }
      .wrapper-img, .content {
        position: relative;
        transition: 1s;
      }
      .wrapper-img {
        padding-bottom: 70px;
        left: -2000px;
        position: relative;
        @include max-w-md {
          padding-bottom: 0;
          max-width: 300px;
        }
        .absolute-buttons {
          z-index: 9;
          text-align: left;
          max-width: 320px;
          position: absolute;
          left: 0;
          bottom: 0;
          @include max-w-md {
            margin-bottom: 12px;
            position: static;
            order: 2;
          }
        }
      }
      .content {
        right: -2000px;
        max-width: 420px;
        display: flex;
        flex-direction: column;
        text-align: right;
        @include max-w-md {
          max-width: none;
          text-align: left;
        }
        .steps-list {
          counter-reset: section;
          li {
            line-height: 20px;
            &::before {
              content: counter(section)'.';
              counter-increment: section;
            }
          }
        }
        .payment-systems {
          img {
            max-width: 200px;
          }
        }
        .btn-faq {
          align-self: flex-end;
          text-align: left;
          max-width: 300px;
          @include max-w-md {
            margin-bottom: 24px;
            order:32;
            align-self: flex-start;
          }
        }
      }
    }
  }
</style>
